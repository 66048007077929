<template>
<div>
    <el-card class="content">
        <div>
            <div class='text target'>{{blog.target}}</div>
            <div class='text title'>{{blog.title}}</div>
            <div class='text abstract'>{{blog.abstract}}</div>
            <el-image style="width: 90vw; height: 55vh" :src="blog.cover" fit="cover" :lazy="true"></el-image>
        </div>
        <!-- <div>title</div> -->
    </el-card>
</div>
</template>

<script>
export default {
    props: ["blog"],
    data() {
        return {
            true: true,
            img: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            title: '这是什么垃圾？',
            abstract: '语言垃圾分类助手《什么垃圾》',
            target: '生活解决方案'
        }
    },
};
</script>

<style scoped>
.content {
    width: 90vw;
    height: 55vh;
    margin: 0 auto;
    border-radius: 5vw;
    position: relative;
    background: rgba(255, 255, 255, 0.76);
    box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, .3);
}

.el-image {
    position: absolute;
    top: 0;
    left: 0;

    /* z-index: 1 !important; */
}

.text {
    text-align: left;
    position: absolute;
    width: 82vw;
    left: 4vw;
    z-index: 5;
}

.title {
    top: 6vh;
    font-size: 2.3rem;
}

.abstract {
    top: 35vh;
    font-size: 1.3rem;
}

.target {
    /* top: 5vh; */
    font-size: 1rem;
    color: rgb(149, 149, 150)
}
</style>
