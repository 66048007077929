<template>
<keep-alive>
    <div class="main" ref="scollbar">
        <div class="mainwidth date">
            <span>{{ date.month }}月{{ date.day }}日 星期{{ date.week }}</span>
        </div>
        <div class="mainwidth title">
            <span class="today">Today</span>
            <span class="avatar">
                <router-link to="/admin">
                    <el-avatar :size="45" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
                </router-link>
            </span>
        </div>
        <div v-for="blog in blogs" :key="blog._id">
            <div @click="detail(blog._id)">
                <b-card class="cd" :blog="blog"></b-card>
            </div>
        </div>
        <div ><a class="public" href="https://beian.miit.gov.cn">津ICP备20000857号-2</a></div>
        
    </div>
</keep-alive>
</template>

<script>
import url from "@/serviceAPI.config.js";
import BCard from "@/common/BCard";
export default {
    data() {
        return {
            date: {
                month: "",
                day: "",
                week: "",
            },
            blogs: [],
        };
    },
    components: {
        BCard,
    },
    methods: {
        getdate() {
            var date = new Date();
            var weekday = ['日', '一', '二', '三', '四', '五', '六'];
            // var year = date.getFullYear();
            this.date.month = date.getMonth() + 1;
            this.date.day = date.getDate();
            this.date.week = weekday[date.getDay()];
            this.$http.get(url.blog).then((response) => {
                    //  console.log( response.data.data);
                    this.blogs = response.data.data;
                })
                .catch((error) => {
                    // console.log(error);
                    // ！！！出现错误怎么再次请求数据
                    this.getdate();
                })
        },
        handleScroll() {
            this.$refs.scollbar.scrollLeft += 10
        },
        detail(id) {
            this.$router.push({
                path: '/blogdetail',
                query: {
                    id: id
                }
            })
        }
    },
    // mounted() {
    //     window.addEventListener('scroll', this.handleScroll(), true)
    // },
    created: function () {
        this.getdate();
    },
}
</script>

<style scoped>
.main {
    background-color: #fff;
}

.mainwidth {
    margin: 0 5vw;
    text-align: left;
    vertical-align: middle;
}

.date {
    padding: 1em 0em;
}

.title {
    height: 5vh;
    font-size: 2rem;
    font-weight: 700;
    line-height: 5vh;
}

.today {
    margin: 1.5vh;
    padding: 0em;
    margin: 0rem;
}

.avatar {
    margin-top: -0.1em;
    float: right;
}

.cd {
    margin: 1rem;
    margin-bottom: 4vh;
}
.public{
    color: #959596 !important;
}
</style>
